import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks } from '../../components';
import css from './SearchResultsPanel.module.css';
import Carousel from 'react-multi-carousel';
import './CarouselStyles.css';
import { categoryDisplay } from '../../containers/SearchPage/SearchPage.duck';

const screenWidth = typeof window !== 'undefined' && window.innerWidth;
const slidesToShow = () => {
  if (screenWidth < 1024) {
    return 1;
  } else if (screenWidth < 1921) {
    return 3;
  } else {
    return 4;
  }
};

const responsive = {
  largeDesktop: {
    breakpoint: { max: 5000, min: 1921 },
    items: 4.2,
    slidesToSlide: 4,
  },
  desktop: {
    breakpoint: { max: 1920, min: 1024 },
    items: 3.2,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 1023, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const CardsCollection = props => {
  const {
    cards = [],
    title = '',
    pagination,
    totalItems,
    props: p,
    i,
    selectedListingTab,
    currentUser,
    onUpdateFavourites,
    recommendedFlag,
    selectedWorktripp,
    selectedListingTabId,
  } = props;
  const classes = classNames(p.rootClassName || css.root, p.className);

  const theSearch = typeof window !== 'undefined' && window.location.search;
  const queryParams = parse(theSearch);
  const currentUrlPage = queryParams[`pageC${i}`];
  const showNext = pagination && pagination.totalItems > pagination.perPage;

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={p.search}
        pagePathParams={{ slug: selectedListingTabId }}
        pagination={pagination}
        onSearchListingsByCategory={p.onSearchListingsByCategory}
        listingsIndex={i}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 99;
  const panelLargeWidth = 99;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth / 3}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 4}vw`,
    `${panelLargeWidth / 5}vw`,
  ].join(', ');

  const showCardCollection =
    selectedListingTab == 'All' || selectedListingTab == title || recommendedFlag;
  const carouselView = selectedListingTab == 'All';

  return showCardCollection ? (
    <div className={classes}>
      {(carouselView || recommendedFlag) && (
        <h2 className={css.listingCardsTitle}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>
              {title}{' '}
              {!recommendedFlag && <span style={{ fontSize: '18px' }}>({totalItems} results)</span>}
            </span>
          </div>
        </h2>
      )}
      <div className={css.listingCards}>
        {carouselView ? (
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            ssr={false}
            infinite={false}
            keyBoardControl={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            containerClass={css.carouselContainer}
            itemClass={css.carouselItem}
            afterChange={(previousSlide, { currentSlide, onMove }) => {
              if (showNext && currentSlide && currentSlide + slidesToShow() >= cards.length - 0.5) {
                p.onSearchListingsByCategory(p.search, i, pagination.perPage + 24);
              }
            }}
          >
            {cards.map(l => (
              <ListingCard
                className={css.listingCard}
                key={l.id.uuid}
                listing={l}
                renderSizes={cardRenderSizes}
                setActiveListing={p.setActiveListing}
                currentUser={currentUser}
                onUpdateFavourites={onUpdateFavourites}
                showFavoriteIcons={true}
                maxCharacters={30}
                selectedWorktripp={selectedWorktripp}
                recommendedFlag={recommendedFlag}
              />
            ))}
          </Carousel>
        ) : (
          cards.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={p.setActiveListing}
              currentUser={currentUser}
              onUpdateFavourites={onUpdateFavourites}
              showFavoriteIcons={true}
            />
          ))
        )}
      </div>
      {!carouselView ? paginationLinks : null}
    </div>
  ) : null;
};

const SearchResultsPanel = props => {
  const {
    listings4,
    listings1,
    listings2,
    listings3,
    pagination4,
    pagination1,
    pagination2,
    pagination3,
    totalItems4,
    totalItems1,
    totalItems2,
    totalItems3,
    // onSearchListingsByCategory,
    selectedListingTab,
    currentUser,
    onUpdateFavourites,
    selectedWorktripp,
    worktrippFiltersActive,
    reccomendedListings,
  } = props;

  const filteredRecommendedListings =
    selectedListingTab == 'all'
      ? reccomendedListings
      : reccomendedListings.filter(listing =>
          selectedListingTab.split(',').includes(listing.attributes.publicData.listingType)
        );

  return (
    <>
      {worktrippFiltersActive && filteredRecommendedListings.length > 0 && (
        <div className={css.recommendedSection}>
          <CardsCollection
            props={props}
            cards={filteredRecommendedListings}
            title={'Recommended'}
            selectedListingTab={categoryDisplay[selectedListingTab]}
            i={0}
            currentUser={currentUser}
            onUpdateFavourites={onUpdateFavourites}
            recommendedFlag={true}
            selectedListingTabId={selectedListingTab}
            selectedWorktripp={selectedWorktripp}
          />
        </div>
      )}
      <CardsCollection
        props={props}
        cards={listings4}
        pagination={pagination4}
        totalItems={totalItems4}
        title={categoryDisplay['retreat']}
        selectedListingTab={categoryDisplay[selectedListingTab]}
        i={0}
        currentUser={currentUser}
        onUpdateFavourites={onUpdateFavourites}
        selectedWorktripp={selectedWorktripp}
        selectedListingTabId={selectedListingTab}
      />
      <CardsCollection
        props={props}
        cards={listings1}
        pagination={pagination1}
        totalItems={totalItems1}
        title={categoryDisplay['venue']}
        selectedListingTab={categoryDisplay[selectedListingTab]}
        i={1}
        currentUser={currentUser}
        onUpdateFavourites={onUpdateFavourites}
        selectedWorktripp={selectedWorktripp}
        selectedListingTabId={selectedListingTab}
      />
      <CardsCollection
        props={props}
        cards={listings3}
        pagination={pagination3}
        totalItems={totalItems3}
        title={
          categoryDisplay['certifiedCoach,domainSpecialistCoach,expertFacilitator,inspiredSpeaker']
        }
        selectedListingTab={categoryDisplay[selectedListingTab]}
        i={2}
        currentUser={currentUser}
        onUpdateFavourites={onUpdateFavourites}
        selectedWorktripp={selectedWorktripp}
        selectedListingTabId={selectedListingTab}
      />
      <CardsCollection
        props={props}
        cards={listings2}
        pagination={pagination2}
        totalItems={totalItems2}
        title={categoryDisplay['experiences']}
        selectedListingTab={categoryDisplay[selectedListingTab]}
        i={3}
        currentUser={currentUser}
        onUpdateFavourites={onUpdateFavourites}
        selectedWorktripp={selectedWorktripp}
        selectedListingTabId={selectedListingTab}
      />
    </>
  );

  // return (
  //   <div className={classes}>
  //     <div className={css.listingCards}>
  //       {listings.map(l => (
  //         <ListingCard
  //           className={css.listingCard}
  //           key={l.id.uuid}
  //           listing={l}
  //           renderSizes={cardRenderSizes}
  //           setActiveListing={setActiveListing}
  //         />
  //       ))}
  //       {props.children}
  //     </div>
  //     {paginationLinks}
  //   </div>
  // );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  listings1: [],
  listings2: [],
  listings3: [],
  pagination: null,
  pagination1: null,
  pagination2: null,
  pagination3: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  listings1: array,
  listings2: array,
  listings3: array,
  pagination: propTypes.pagination,
  pagination1: propTypes.pagination,
  pagination2: propTypes.pagination,
  pagination3: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
