import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  Button,
  FieldTextInput,
  FieldSelect,
  NamedLink,
  CustomSpinner,
} from '../../components';
import * as validators from '../../util/validators';
import axios from 'axios';
import css from './LoginForm.module.css';
import FieldPasswordInput from '../../components/FieldPasswordInput/FieldPasswordInput';

const LoginFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        values,
      } = fieldRenderProps;

      const emailLabel = (
        <>
          <FormattedMessage id="LoginForm.emailLabel" />
          <span className={css.redStar}>*</span>
        </>
      );
      const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const passwordLabel = (
        <>
          <FormattedMessage id="LoginForm.passwordLabel" />
          <span className={css.redStar}>*</span>
        </>
      );
      const passwordPlaceholder = intl.formatMessage({
        id: 'LoginForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'LoginForm.passwordRequired',
      });
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const accountIdLabel = (
        <>
          <FormattedMessage id="LoginForm.accountIdLabel" />
          <span className={css.redStar}>*</span>
        </>
      );
      const accountIdPlaceholder = intl.formatMessage({
        id: 'LoginForm.accountIdPlaceholder',
      });
      const accountIdRequiredMessage = intl.formatMessage({
        id: 'LoginForm.accountIdRequired',
      });
      const accountIdRequired = validators.required(accountIdRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const passwordRecoveryLink = (
        <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
          <FormattedMessage id="LoginForm.forgotPassword" />
        </NamedLink>
      );

      const [phase, setPhase] = useState(1);
      const [loading, setLoading] = useState(false);
      const [loginIds, setLoginIds] = useState([]);
      const [error, setError] = useState('');

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <div
              style={{
                visibility: phase == 1 ? 'visible' : 'hidden',
                height: phase == 1 ? 'auto' : '0px',
              }}
            >
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <FieldPasswordInput
                className={css.password}
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="current-password"
                label={passwordLabel}
                placeholder={passwordPlaceholder}
                validate={passwordRequired}
              />
              <Button
                style={{ marginTop: '40px' }}
                disabled={!values.email || !values.password}
                onClick={() => {
                  setLoading(true);
                  axios
                    .post(
                      `/api/auth/getLoginIds`,
                      {
                        email: values.email,
                        password: values.password,
                      },
                      {
                        headers: {
                          'Access-Control-Allow-Origin': '*',
                          'Content-Type': 'application/json',
                        },
                      }
                    )
                    .then(res => {
                      setLoginIds(res.data);
                      setPhase(2);
                      setLoading(false);
                      setError('');
                    })
                    .catch(err => {
                      if (err.response && err.response.data == 'No account with that email.') {
                        props.onSubmit({ ...values, accountId: 'pass' });
                      }
                      setError(err.response && err.response.data ? err.response.data : 'Error');
                      setLoading(false);
                    });
                }}
              >
                Proceed
              </Button>
              {error && <div style={{ color: 'red', marginTop: '12px' }}>{error}</div>}
            </div>
            <div
              style={{
                visibility: phase == 2 ? 'visible' : 'hidden',
                height: phase == 2 ? 'auto' : '0px',
              }}
            >
              <FieldSelect
                id={formId ? `${formId}.accountId` : 'accountId'}
                name="accountId"
                className={css.password}
                label={accountIdLabel}
                validate={accountIdRequired}
              >
                <option value="">{accountIdPlaceholder}</option>
                {loginIds.map(acc => {
                  return (
                    <option key={acc._id} value={acc._id}>
                      {acc.email}
                    </option>
                  );
                })}
              </FieldSelect>
            </div>
          </div>
          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.recoveryLinkInfo}>
                <FormattedMessage
                  id="LoginForm.forgotPasswordInfo"
                  values={{ passwordRecoveryLink }}
                />
              </span>
            </p>
            {phase == 2 ? (
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="LoginForm.logIn" />
              </PrimaryButton>
            ) : null}
          </div>
          {loading ? <CustomSpinner /> : null}
        </Form>
      );
    }}
  />
);

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
