import React from 'react';
import { formatDate } from '../../../util/dates';
import { addDays, isAfter, format } from 'date-fns';
import classNames from 'classnames';
import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
import css from '../ActivityFeed.module.css';

const { Money } = sdkTypes;

const PROPOSAL_EXPIRY_DAYS = 7;

const Proposal = props => {
  const { proposal, intl, isCustomer, activeProposalId } = props;

  const {
    workScope,
    noOfParticipants,
    bookingStart,
    bookingEnd,
    proposedPrice,
    createdAt,
    quote,
    proposalId,
  } = proposal;

  const expiryDate = createdAt ? addDays(new Date(createdAt), PROPOSAL_EXPIRY_DAYS) : null;
  const isExpired = isAfter(new Date(), expiryDate) || activeProposalId !== proposalId;

  const hasBreakdown = quote !== undefined && quote !== null;
  const displayGrossPrice = hasBreakdown
    ? formatMoney(intl, new Money(quote.grossMoney, quote.currency))
    : `£${proposedPrice}`;

  const renderPriceBreakdown = () => {
    return (
      <div
        className={classNames(
          css.priceBreakdownContainer,
          isExpired ? css.priceBreakdownExpired : ''
        )}
      >
        <div className={css.priceBreakdownTitle}>
          <h3>
            {isExpired ? 'Expired quote' : 'Quote'}: {displayGrossPrice}
          </h3>
        </div>
        {hasBreakdown && (
          <>
            <hr />
            <div className={css.priceBreakdownContent}>
              <span>
                <strong>Breakdown</strong>
              </span>
              <span>
                Net amount: {formatMoney(intl, new Money(quote.netMoney, quote.currency))}
              </span>
              <span>
                VAT amount: {formatMoney(intl, new Money(quote.vatMoney, quote.currency))}
              </span>
              <span style={{ fontSize: 12, lineHeight: '12px' }}>
                VAT rate of {quote.vatPercentage}%
              </span>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderBookingDetails = () => {
    return (
      <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <BookingLine title="Scope of work" description={workScope} />
        <BookingLine
          title="Booking dates"
          description={`${format(new Date(bookingStart), 'dd/MM/yyyy')} - ${format(
            new Date(bookingEnd),
            'dd/MM/yyyy'
          )}`}
        />
        <BookingLine title="Number of participants" description={noOfParticipants} />
      </div>
    );
  };

  const renderTimings = () => {
    return (
      <div className={css.proposalTimingContent}>
        <ProposalTime text="Received on" date={createdAt} intl={intl} />
        <ProposalTime
          text={isExpired ? 'Expired on' : 'Valid until'}
          date={expiryDate}
          isExpired={isExpired}
          intl={intl}
        />
      </div>
    );
  };

  return (
    <div className={classNames(!isCustomer ? css.ownMessage : css.message, css.proposal)}>
      <div style={{ position: 'relative', width: '100%', minWidth: 300 }}>
        {renderPriceBreakdown()}
        {renderBookingDetails()}
        {renderTimings()}
      </div>
    </div>
  );
};

const ProposalTime = ({ text, date, isExpired, intl }) => {
  if (!date) {
    return <div />;
  }
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  const textClass = classNames(css.messageDate, isExpired ? css.expiredMessage : '');
  return (
    <p className={textClass}>
      {text} <br />
      {formatDate(intl, todayString, new Date(date))}
    </p>
  );
};

const BookingLine = ({ title, description, inline }) => {
  return (
    <p>
      <strong>{title}</strong>
      {inline ? <span> </span> : <br />}
      {description}
    </p>
  );
};

export default Proposal;
