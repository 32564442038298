/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

// @NL: note:
// You can add 'active: false' to a filter to disable it
// No need to comment it out, as it's still used to get values from keys.

export const filters = [
  {
    id: 'goals',
    label: 'Goals',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_specialism'],
    config: {
      searchMode: 'has_any',

      options: [
        {
          key: 'business',
          label: 'Innovate and solve challenges',
          parent: 'Filter Label',
          longLabel: 'Innovate & solve challenges',
        },
        {
          key: 'creativity&Innovation',
          label: 'Creativity & innovation',
          parent: 'business',
        },
        {
          key: 'designThinking&CustomerCentricity&Branding',
          label: 'Design thinking & customer centricity',
          parent: 'business',
        },
        {
          key: 'resilience&reframing ',
          label: 'Resilience & reframing ',
          parent: 'business',
        },

        {
          key: 'storytelling&Branding',
          label: 'Storytelling & branding',
          parent: 'business',
        },

        {
          key: 'company',
          label: 'Improve connection and belonging',
          parent: 'Filter Label',
          longLabel: 'Improve connection and belonging',
        },
        {
          key: 'buildingTrust&Connection',
          label: 'Building trust & connection',
          parent: 'company',
        },
        {
          key: 'celebration&Appreciation',
          label: 'Celebration & appreciation',
          parent: 'company',
        },
        {
          key: 'companycultureandconnectdness',
          label: 'Company culture & values',
          parent: 'company',
        },
        {
          key: 'neurodiversityEqualityDiversityAndInclusion',
          label: 'Diversity, equity & inclusion',
          parent: 'company',
        },
        {
          key: 'onboarding',
          label: 'Onboarding',
          parent: 'company',
        },

        {
          key: 'FemaleWellnessAndLeadership',
          label: 'Workplace wellness',
          parent: 'company',
        },

        {
          key: 'crossTeam',
          label: 'Improve communication and productivity',
          parent: 'Filter Label',
          longLabel: 'Improve communication & productivity',
        },
        {
          key: 'collaboration&WaysOfWorking',
          label: 'Collaboration & ways of working',
          parent: 'crossTeam',
        },
        {
          key: 'conflictResolution',
          label: 'Conflict Resolution',
          parent: 'crossTeam',
        },
        {
          key: 'emotionalIntelligenceAndEmpathy',
          label: 'Emotional intelligence and empathy',
          parent: 'crossTeam',
        },
        {
          key: 'offGridDeepWork',
          label: 'Off Grid / Deep Work',
          parent: 'crossTeam',
        },
        // {
        //   key: "facilitationMcOrWorkshopHosting",
        //   label: 'Facilitation Mc Or Workshop Hosting',
        //   parent: 'crossTeam',
        // },
        {
          key: 'nlp',
          label: 'Personality diagnostics & strength finding & NLP',
          parent: 'crossTeam',
        },

        {
          key: 'employee',
          label: 'Learn and develop skills',
          parent: 'Filter Label',
          longLabel: 'Learn & develop skills',
        },
        {
          key: 'improvingConfidence',
          label: 'Confidence',
          parent: 'employee',
        },
        {
          key: 'leadership',
          label: 'Leadership',
          parent: 'employee',
        },
        {
          key: 'prioritisation&TimeManagement',
          label: 'Prioritisation & time management',
          parent: 'employee',
        },
        {
          key: 'publicSpeakingAndCommunicationSkills',
          label: 'Public Speaking And Communication Skills',
          parent: 'employee',
        },

        {
          key: 'scalingAndSalesMindset',
          label: 'Sales & marketing',
          parent: 'employee',
        },

        {
          key: 'org',
          label: 'Look forward and plan',
          parent: 'Filter Label',
          longLabel: 'Look forward & plan',
        },
        {
          key: 'goalSetting&Alignment',
          label: 'Goal-setting & alignment',
          parent: 'org',
        },
        {
          key: 'organisationalDesign',
          label: 'Organisational design',
          parent: 'org',
        },
        {
          key: 'purpose&VisionSetting',
          label: 'Purpose & vision-setting',
          parent: 'org',
        },
        {
          key: 'strategyDevelopment',
          label: 'Strategy development',
          parent: 'org',
        },
        {
          key: 'retrospectiveWashUp',
          label: 'Retrospective / Wash up',
          parent: 'org',
        },
      ],
    },
  },
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
    active: false,
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 3000,
      step: 50,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    active: false,
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  // the type filter isn't needed any more after the listings categorization
  {
    id: 'type',
    label: 'Listing Type',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_listingType'],
    active: false,
    config: {
      options: [
        { key: 'cfs', label: 'Coach, Facilitator, Speaker', parent: 'Filter Label' },
        { key: 'certifiedCoach', label: 'Workshop', parent: 'CFS' },
        { key: 'domainSpecialistCoach', label: 'Workshop', parent: 'CFS' },
        { key: 'expertFacilitator', label: 'Workshop', parent: 'CFS' },
        { key: 'inspiredSpeaker', label: 'Speaker', parent: 'CFS' },
        { key: 've', label: 'Venues & Experiences', parent: 'Filter Label' },
        { key: 'venue', label: 'Venue', parent: 'VE' },
        { key: 'retreat', label: 'Retreat organiserss', parent: 'VE' },
        { key: 'experiences', label: 'Experiences', parent: 'VE' },
      ],
    },
  },

  {
    id: 'capacity',
    label: 'Capacity',
    type: 'MultiFieldFilter',
    group: 'primary',
    queryParamNames: ['pub_maxEventCapacity', 'pub_noOfUniqueBedrooms', 'pub_maxSleepCapacity'],
    config: {
      options: [
        { key: 'pub_maxEventCapacity', label: 'Minimum event capacity', parent: 'capacity' },
        { key: 'pub_noOfUniqueBedrooms', label: 'No. of unique bedrooms', parent: 'capacity' },
        { key: 'pub_maxSleepCapacity', label: 'Minimum sleep capacity', parent: 'capacity' },
      ],
    },
  },
  {
    id: 'venueVibe',
    label: 'Vibes',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_venueVibe'],
    config: {
      searchMode: 'has_any',

      options: [
        { key: 'venue', label: 'Venue vibe', parent: 'Filter Label' },
        {
          key: 'luxe',
          label: 'Luxe',
          description: '5-star facilities & service',
          parent: 'venue',
        },
        {
          key: 'effortless',
          label: 'Effortless',
          description: 'closer to 4-star, everything you need for an easy, comfortable experience',
          parent: 'venue',
        },
        {
          key: 'wild',
          label: 'Wild',
          description: 'really rural with basic facilities',
          parent: 'venue',
        },
      ],
    },
  },
  {
    id: 'venueType',
    label: 'Types',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_venueType'],
    config: {
      searchMode: 'has_any',

      options: [
        { key: 'venue', label: 'Venue type', parent: 'Filter Label' },
        {
          key: 'hotel',
          label: 'Hotel',
          parent: 'venue',
        },
        {
          key: 'coworkingSpace',
          label: 'Co-working space',
          parent: 'venue',
        },
        {
          key: 'privateHouseApartment',
          label: 'Private house/Apartment',
          parent: 'venue',
        },
        {
          key: 'Glamping',
          label: 'Glamping',
          parent: 'venue',
        },
        {
          key: 'bunkHouse',
          label: 'BunkHouse',
          parent: 'venue',
        },
        { key: 'exp', label: 'Experience type', parent: 'Filter Label' },
        {
          key: 'indoor',
          label: 'Indoor activities',
          parent: 'experiences',
        },
        {
          key: 'outdoor',
          label: 'Outdoor activities',
          parent: 'experiences',
        },
        {
          key: 'FnD',
          label: 'Food & drink',
          parent: 'experiences',
        },
        {
          key: 'sports',
          label: 'Sports & physical',
          parent: 'experiences',
        },
        {
          key: 'art',
          label: 'Art & craft',
          parent: 'experiences',
        },
        {
          key: 'theatre',
          label: 'Performance theatre',
          parent: 'experiences',
        },
        {
          key: 'nature',
          label: 'Nature',
          parent: 'experiences',
        },
        {
          key: 'onWater',
          label: 'On & in the water',
          parent: 'experiences',
        },
        {
          key: 'yoga',
          label: 'Meditation & yoga',
          parent: 'experiences',
        },
        {
          key: 'problemSolving',
          label: 'Problem-solving',
          parent: 'experiences',
        },
        {
          key: 'spa',
          label: 'Spa & relaxation',
          parent: 'experiences',
        },
        {
          key: 'other',
          label: 'Other',
          parent: 'experiences',
        },
      ],
    },
  },
  {
    id: 'listingSector',
    label: 'Sector Experience',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_listingSector'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      options: [
        {
          key: 'artsCreative',
          label: 'Arts / Creative',
        },
        {
          key: 'automotive',
          label: 'Automotive',
        },
        {
          key: 'acceleratorCohort',
          label: 'Accelerator / Cohort',
        },
        {
          key: 'accounting',
          label: 'Accounting',
        },
        {
          key: 'architecture',
          label: 'Architecture',
        },
        {
          key: 'biotech',
          label: 'Biotech',
        },
        {
          key: 'construction',
          label: 'Construction',
        },
        {
          key: 'education',
          label: 'Education',
        },
        {
          key: 'energy',
          label: 'Energy',
        },
        {
          key: 'engineering',
          label: 'Engineering',
        },
        {
          key: 'fmgc',
          label: 'FMGC',
        },
        {
          key: 'finance',
          label: 'Finance',
        },
        {
          key: 'hospitality',
          label: 'Hospitality',
        },
        {
          key: 'health',
          label: 'Health',
        },
        {
          key: 'legal',
          label: 'Legal',
        },
        {
          key: 'manufacturing',
          label: 'Manufacturing',
        },
        {
          key: 'music',
          label: 'Music',
        },
        {
          key: 'multinational',
          label: 'Multinational',
        },
        {
          key: 'ngo',
          label: 'NGO',
        },
        {
          key: 'pharma',
          label: 'Pharma',
        },
        {
          key: 'retail',
          label: 'Retail',
        },
        {
          key: 'sustainability',
          label: 'Sustainability',
        },
        {
          key: 'space',
          label: 'Space',
        },
        {
          key: 'startup',
          label: 'Startup',
        },
        {
          key: 'scaleUp',
          label: 'ScaleUp',
        },
        {
          key: 'tech',
          label: 'Tech',
        },
        {
          key: 'telecommunications',
          label: 'Telecommunications',
        },
      ],
    },
  },

  {
    id: 'modeOfEngagement',
    label: 'Preferred mode of engagement',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_modeOfEngagement'],
    config: {
      searchMode: 'has_any',

      options: [
        {
          key: '1:1',
          label: '1:1',
        },
        {
          key: '1toMany',
          label: 'Group',
        },
        {
          key: 'virtual',
          label: 'Virtual',
        },
        {
          key: 'inPerson',
          label: 'In-person',
        },
      ],
    },
  },

  {
    id: 'facilities',
    label: 'Facilities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_alsoIncludes'],
    config: {
      searchMode: 'has_any',

      options: [
        { key: 'cateringOptions', label: 'Catering options', parent: 'Filter Label' },
        {
          key: 'onsiteRestaurant',
          label: 'Onsite restaurant',
          parent: 'cateringOptions',
        },
        {
          key: 'onsiteBar',
          label: 'Onsite bar',
          parent: 'cateringOptions',
        },
        {
          key: 'cateringAvailable',
          label: 'Catering available',
          parent: 'cateringOptions',
        },
        {
          key: 'selfCatering',
          label: 'Self catering',
          parent: 'cateringOptions',
        },

        { key: 'meetingSpace', label: 'Meeting space', parent: 'Filter Label' },
        {
          key: 'numberOfHireableSpaces',
          label: 'Number of hireable spaces',
          parent: 'meetingSpace',
          extraInputType: 'number',
        },
        {
          key: 'numberOfBreakoutAreas',
          label: 'Number of breakout areas',
          parent: 'meetingSpace',
          extraInputType: 'number',
        },
        {
          key: 'exclusiveUseAvailable',
          label: 'Exclusive use available',
          parent: 'meetingSpace',
        },
        {
          key: 'wifi',
          label: 'Wifi',
          parent: 'meetingSpace',
          extraInputType: 'select',
          selectOptions: [
            { key: 'strong', label: 'Strong' },
            { key: 'fair', label: 'Fair' },
            { key: 'poor', label: 'Poor' },
            { key: 'none', label: 'None' },
          ],
        },

        { key: 'workshopTools', label: 'Workshop tools', parent: 'Filter Label' },
        {
          key: 'screen',
          label: 'Screen',
          parent: 'workshopTools',
          // extraInputType: 'number',
        },
        {
          key: 'avSystem',
          label: 'AV system',
          parent: 'workshopTools',
        },
        {
          key: 'projector',
          label: 'Projector',
          parent: 'workshopTools',
        },
        {
          key: 'flipChart',
          label: 'Flip chart',
          parent: 'workshopTools',
        },

        { key: 'outdoor', label: 'Outdoor / recreation space', parent: 'Filter Label' },
        {
          key: 'grounds',
          label: 'Grounds',
          parent: 'outdoor',
          extraInputType: 'text',
        },
        {
          key: 'coveredMeetingArea',
          label: 'Covered meeting area',
          parent: 'outdoor',
          extraInputType: 'text',
        },
        {
          key: 'onsiteActivities',
          label: 'Onsite activities',
          parent: 'outdoor',
          extraInputType: 'text',
        },
        {
          key: 'gymSpa',
          label: 'Gym / Spa',
          parent: 'outdoor',
        },
        {
          key: 'firePit',
          label: 'Fire pit',
          parent: 'outdoor',
        },

        { key: 'accessibility', label: 'Accessibility', parent: 'Filter Label' },
        {
          key: 'wheelchairAccess',
          label: 'Wheelchair access',
          parent: 'accessibility',
          extraInputType: 'select',
          selectOptions: [
            { key: 'full-access', label: 'Full access' },
            { key: 'partial-access', label: 'Partial access' },
            { key: 'limited-access', label: 'Limited access' },
          ],
        },
        {
          key: 'downstairsBedrooms',
          label: 'Downstairs bedrooms',
          parent: 'accessibility',
          extraInputType: 'select',
          selectOptions: [
            { key: '1', label: '1' },
            { key: '2', label: '2' },
            { key: '3', label: '3' },
            { key: '4', label: '4' },
            { key: '5', label: '5' },
          ],
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'pub_priority', label: 'Recommended' },
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const goalsLabels = [
  { title: 'Improve connection & belonging', key: 'company' },
  { title: 'Learning & developing skills', key: 'employee' },
  { title: 'Improve innovation & problem solving', key: 'business' },
  { title: 'Planning & looking ahead', key: 'org' },
  { title: 'Improve communication & productivity', key: 'crossTeam' },
];
