import React, { Component, useEffect, useState } from 'react';
import { string, number, bool, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { convertMoneyToNumber, formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import {
  getListingImagesOnly,
  unparseListingType,
  unparseListingTxStatus,
  calculateExpectedPercentageOfWorktrippBudget,
  calculateTotalBudget,
} from '../../util/misc';
import config from '../../config';
import { NamedLink, ResponsiveImage, Button } from '../../components';
import css from './ListingCard.module.css';
import starIcon from '../../assets/icons/star.png';
import listingDeleteIcon from '../../assets/icons/listingDeleteIcon.png';
import { useDispatch, useSelector } from 'react-redux';
import { getFavouriteListings } from '../../containers/SearchPage/SearchPage.duck';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price, true);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    isWtCard,
    setEnquiryModalOpen,
    setSelectedListing,
    listingTx,
    onRemoveListingFromWorktripp,
    noOfParticipants,
    noOfDays,
    showPercentageBudget,
    totalBudget,
    currentUser,
    onUpdateFavourites,
    showFavoriteIcons,
    maxCharacters,
    selectedWorktripp,
    recommendedFlag,
  } = props;

  const dispatch = useDispatch();
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);

  const isOwnListing = author?.id?.uuid === currentUser?.id?.uuid;

  // const authorName = author.attributes.profile.displayName;
  const { averageRating = 0, reviewsCount = 0 } = author?.attributes?.profile?.publicData || {};

  const clientTestimonials = currentListing.attributes.publicData.clientTestimonials || [];
  const listingImagesOnly = getListingImagesOnly(currentListing.images, clientTestimonials);
  const firstImage =
    listingImagesOnly && listingImagesOnly.length > 0 ? listingImagesOnly[0] : null;
  const { serviceType, listingType, specialism } = publicData;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const specialismOptions = findOptionsForSelectFilter('goals', config.custom.filters);

  function renderSelectedSpecialismCategories() {
    const categories = [];
    specialism.map(sp => {
      const result = specialismOptions.find(item => item.key == sp);
      if (!result) return;
      const parnetCat = result.parent;
      const displayValue = specialismOptions.find(e => e.key == parnetCat)?.label;
      if (displayValue && categories.length < 3) {
        categories.push(displayValue);
      }
    });
    return [...new Set(categories)].join(' • ');
  }

  const getEstimateCostBasedOnSelectedWorktripp = () => {
    const rate = convertMoneyToNumber(price);
    const estimatedCost = calculateTotalBudget(rate, noOfParticipants, noOfDays);
    return calculateExpectedPercentageOfWorktrippBudget(estimatedCost, totalBudget);
  };

  const [liked, setLiked] = useState(false);
  const heartIconClass = liked ? `${css.heartIcon} ${css.liked}` : css.heartIcon;
  const showHeartIcon = showFavoriteIcons && !isOwnListing;
  const { SearchPage: searchPageReducer } = useSelector(state => state);
  const { updateFavouritesInProgress } = searchPageReducer || {};
  const userFavouriteListings =
    (currentUser && currentUser.attributes.profile.publicData.favoriteListings) || {};

  const favouriteListings = () => {
    if (currentUser) {
      if (selectedWorktripp?._id) {
        return userFavouriteListings[selectedWorktripp._id] || [];
      } else {
        return userFavouriteListings?.general || [];
      }
    } else {
      const unparsedFavouriteListings =
        typeof window !== 'undefined' && window.localStorage.getItem('favouriteListings');
      const parsedFavourtiteListings = unparsedFavouriteListings
        ? JSON.parse(unparsedFavouriteListings)
        : [];
      return parsedFavourtiteListings;
    }
  };

  const isLiked = () => {
    const likedListings = favouriteListings();
    return likedListings && likedListings.includes(currentListing.id.uuid);
  };

  const toggleFavourite = event => {
    event.preventDefault();
    if (updateFavouritesInProgress) return;
    setLiked(!liked);

    const likedListings = favouriteListings();
    let newListings = likedListings ? likedListings : [];
    if (isLiked()) {
      newListings = likedListings.filter(l => l !== currentListing.id.uuid);
    } else {
      newListings.push(currentListing.id.uuid);
    }
    if (currentUser) {
      const cartToLoad = selectedWorktripp?._id || 'general';
      onUpdateFavourites(newListings, cartToLoad);
    } else {
      dispatch(getFavouriteListings(newListings));
      window.localStorage.setItem('favouriteListings', JSON.stringify(newListings));
    }
  };

  useEffect(() => {
    setLiked(isLiked());
  }, [currentUser, selectedWorktripp]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      setLiked(isLiked());
    });
  }, []);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const { listingLabel, labelColor, showPayButton, payButtonText } = unparseListingTxStatus(
    listingTx
  );

  const isTxPassEnquiry = listingLabel !== 'NOT CONTACTED';

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const onClickChatButton = () => {
    if (isTxPassEnquiry) {
      window.location.assign(`/order/${listingTx.id}/details`);
    } else {
      setSelectedListing(currentListing);
      setEnquiryModalOpen(true);
    }
  };

  const onClickPayButton = () => {
    if (!showPayButton) {
      return;
    }
    // TODO: HACK until current transaction loading gets fixed
    if (payButtonText === 'Pay balance') {
      window.location.assign(`/order/${listingTx.id}/details`);
      return;
    }

    window.location.assign(`/order/${listingTx.id}/details/redirect`);
  };

  const onClickDelete = () => {
    onRemoveListingFromWorktripp(id);
  };

  return (
    <div className={classes}>
      {isWtCard && (
        <div className={css.actionButtonsOnListingCardContainer}>
          <div style={{ color: labelColor }} className={css.listingStatus}>
            {listingLabel ? <span>{listingLabel}</span> : <div />}
          </div>

          <div onClick={onClickDelete} className={css.listingDelete}>
            <img src={listingDeleteIcon} />
          </div>
        </div>
      )}
      <NamedLink name="ListingPage" params={{ id, slug, listingType }}>
        {showHeartIcon && (
          <div className={css.heartImageWrapper}>
            <div className={css.listingLike}>
              <div
                className={
                  updateFavouritesInProgress
                    ? classNames(css.disabled, heartIconClass)
                    : heartIconClass
                }
                onClick={toggleFavourite}
              ></div>
            </div>
          </div>
        )}
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          <div className={css.aspectWrapper}>
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes={renderSizes}
            />
          </div>
        </div>
        <div className={css.preInfo}>
          <div
            className={
              serviceType == 'CFS'
                ? css.serviceTypeCFS
                : listingType == 'venue'
                ? css.serviceTypeV
                : css.serviceTypeE
            }
          >
            {unparseListingType(listingType)}
          </div>
        </div>
        {serviceType == 'CFS' && !recommendedFlag && (
          <div className={css.bulletPoints}>{renderSelectedSpecialismCategories()}</div>
        )}
        <div className={css.reviewsText}>
          {reviewsCount ? (
            <>
              <img src={starIcon} />
              <span>
                {averageRating} ({reviewsCount} reviews)
              </span>
            </>
          ) : null}
        </div>
        <div className={css.info}>
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
            <div className={css.perUnit}>
              <FormattedMessage id={unitTranslationKey} />
              <br></br>
              <FormattedMessage id="ListingCard.perDay2" />
            </div>
            <div>
              {false &&
                showPercentageBudget &&
                `(${getEstimateCostBasedOnSelectedWorktripp()} of total budget)`}
            </div>
          </div>
          <div className={css.mainInfo}>
            <div className={css.title}>
              {richText(
                maxCharacters && title.length > maxCharacters
                  ? `${title.substring(0, maxCharacters)}...`
                  : title,
                {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                }
              )}
            </div>
            {/* <div className={css.authorInfo}>
            <FormattedMessage id="ListingCard.hostedBy" values={{ authorName }} />
          </div> */}
          </div>
        </div>
      </NamedLink>
      {isWtCard && (
        <div className={css.twobuttons}>
          {showPayButton && (
            <Button className={css.acceptButton} onClick={onClickPayButton}>
              {payButtonText}
            </Button>
          )}

          <Button onClick={onClickChatButton} className={css.enquireButton}>
            {listingTx?.status ? 'Chat' : 'Enquire'}
          </Button>
        </div>
      )}
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  noOfParticipants: number,
  noOfDays: number,
  showPercentageBudget: bool,
  totalBudget: number,
  // Responsive image sizes hint
  renderSizes: string,
  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
