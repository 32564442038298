import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';
import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
    onDeclineEnquiry,
    isProvider,
    worktripp,
    setIsProposedPriceModalOpen,
    paymentInProgress,
  } = props;

  const buttonsDisabled = acceptInProgress || declineInProgress;

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  if (!showButtons) {
    return null;
  }

  return (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
        {paymentInProgress && isProvider ? (
          <div style={{ color: 'var(--attentionColor)' }}>Waiting for payments</div>
        ) : null}
      </div>
      <div className={css.actionButtonWrapper}>
        {isProvider ? (
          <>
            <SecondaryButton
              inProgress={declineInProgress}
              disabled={buttonsDisabled || paymentInProgress}
              onClick={() => onDeclineEnquiry('provider')}
            >
              <FormattedMessage id="TransactionPanel.declineButton" />
            </SecondaryButton>
            <PrimaryButton
              inProgress={acceptInProgress}
              disabled={buttonsDisabled || paymentInProgress}
              onClick={() => {
                setIsProposedPriceModalOpen(true);
              }}
            >
              <FormattedMessage id="TransactionPanel.sendQuote" />
            </PrimaryButton>
          </>
        ) : (
          <>
            <SecondaryButton
              inProgress={declineInProgress}
              disabled={buttonsDisabled || paymentInProgress}
              onClick={() => onDeclineEnquiry('customer')}
              className={css.cancelButton}
            >
              <FormattedMessage id="TransactionPanel.cancelEnquiry" />
            </SecondaryButton>
            <PrimaryButton
              disabled={buttonsDisabled}
              onClick={() => {
                window.location.assign(`/viewWorktripp/${worktripp._id}`);
              }}
            >
              <FormattedMessage id="TransactionPanel.manageWorktripp" />
            </PrimaryButton>
          </>
        )}
      </div>
    </div>
  );
};

export default SaleActionButtonsMaybe;
