import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  transitions,
  TRANSITION_CONFIRM_THE_PAYMENT,
  TRANSITION_CONFIRM_AND_PAYOUT_PAYMENT,
  TRANSITION_COMPLETE,
  TRANSITION_COMPLETE_BY_OPERATOR,
  txIsAccepted,
  txIsAcceptedDeposit,
  txIsCancelled,
  txIsCancelledBooked,
  txIsEnquired,
  txIsToDeclinedInquiry,
  txToIsDeclinedQuote,
  txIsToExpiredInquiry,
  txIsToExpiredQuote,
  txIsToQuoteSent,
  txIsPaymentExpired,
  txIsDepositExpired,
  txIsPaymentPending,
  txIsDepositPending,
  txIsBooked,
  txHasAdditionalPaymentInitiated,
  txIsToBookingCreated,
  isPayingTransition,
  getPayingButtonText,
} from '../../util/transaction';
import { hasAdditonalPaymentPendingStatus } from '../../util/transactionHelpers';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
  PrimaryButton,
  Button,
  CustomSpinner,
} from '../../components';
import { SendMessageForm } from '../../forms';
import ProposePriceModal from './ProposePriceModal';
import config from '../../config';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import CardLogo from '../../assets/icons/cardLogo.png';
import GoalLogo from '../../assets/icons/goalDark.png';
import CalendarLogo from '../../assets/icons/calendarDark.png';
import GroupLogo from '../../assets/icons/groupDark.png';
import { findOptionsForSelectFilter } from '../../util/search';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_EXPIRED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
  HEADING_DEPOSIT_DELIVERED,
} from './PanelHeading';
import { unparseListingType } from '../../util/misc';
import css from './TransactionPanel.module.css';
import PhoneNumberModal from './PhoneNumberModal';
import TutorialsChatPage from '../TutorialChatPage/TutorialChatPage';
import ChatActionsSection from '../ChatActionsSection/ChatActionsSection';
import OldTxWarning from './OldTxWarning/OldTxWarning';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      isProposedPriceModalOpen: false,
      isPhoneNumberModalOpen: false,
      showChat: false,
      customLoading: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.setIsProposedPriceModalOpen = this.setIsProposedPriceModalOpen.bind(this);
    this.setIsPhoneNumberModalOpen = this.setIsPhoneNumberModalOpen.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
    const currentTransaction = ensureTransaction(this.props.transaction);
    const currentProvider = ensureUser(currentTransaction.provider);
    if (currentProvider?.attributes?.profile?.publicData) {
      this.setState({
        isPhoneNumberModalOpen: currentProvider?.attributes?.profile?.publicData?.phoneNumber
          ? false
          : true,
      });
    }
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }
  setIsProposedPriceModalOpen(condition) {
    this.setState({ isProposedPriceModalOpen: condition });
  }

  setIsPhoneNumberModalOpen(condition) {
    this.setState({ isPhoneNumberModalOpen: condition });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage, currentUser } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }

    onSendMessage(ensuredTransaction.id, message, currentUser.id.uuid)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      currentUserHasListings,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onSendQuote,
      onAcceptSaleCustomer,
      onAcceptSaleCustomerAdditionalTx,
      onDeclineEnquiry,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      timeSlots,
      fetchTimeSlotsError,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      blockAllTxFunctions,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';
    const closeButtonMessage = intl.formatMessage({ id: 'ReviewModal.later' });

    const { metadata, lastTransition } = currentTransaction.attributes;
    const {
      txType,
      ['full-payment']: fullPayment,
      ['final-payment']: finalPayment,
      ['deposit-payment']: depositPayment,
      originalTxId,
    } = metadata || {};

    const isOriginalTx = txType && txType == 'original';
    const hasUnpaidFullPayment = isOriginalTx && fullPayment && fullPayment.status == 'pending';
    const hasUnpaidFinalPayment = isOriginalTx && finalPayment && finalPayment.status == 'pending';
    const hasUnpaidDepositPayment =
      isOriginalTx && depositPayment && depositPayment.status == 'pending';

    if (isCustomer) {
      if (hasUnpaidFullPayment) {
        neededTxId = fullPayment.id;
      } else if (hasUnpaidDepositPayment) {
        neededTxId = depositPayment.id;
      } else if (hasUnpaidFinalPayment) {
        neededTxId = finalPayment.id;
      }
    }

    const paymentExpiryDateTs = hasUnpaidFullPayment
      ? fullPayment.date
      : hasUnpaidFinalPayment
      ? finalPayment.date
      : hasUnpaidDepositPayment
      ? depositPayment.date
      : null;
    const paymentExpiryDate = paymentExpiryDateTs && new Date(paymentExpiryDateTs);
    const paymentExpired = paymentExpiryDate && new Date() - paymentExpiryDate > 60 * 60 * 1000;
    const paymentInProgress = paymentExpiryDate && !paymentExpired;

    const hasCompletedPayment =
      lastTransition == TRANSITION_CONFIRM_THE_PAYMENT ||
      lastTransition == TRANSITION_CONFIRM_AND_PAYOUT_PAYMENT;
    if (isCustomer && originalTxId && hasCompletedPayment) {
      window.location.assign(`/order/${originalTxId}/details`);
    }

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const savedLineItems = currentTransaction?.attributes?.lineItems;

    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: false,
          showSaleButtons: blockAllTxFunctions ? false : !isCustomerBanned,
        };
      } else if (txIsToDeclinedInquiry(tx) || txToIsDeclinedQuote(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsToExpiredInquiry(tx) || txIsToExpiredQuote(tx)) {
        return {
          headingState: HEADING_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsToQuoteSent(tx)) {
        return {
          headingState: HEADING_ENQUIRED,
          showSaleButtons: blockAllTxFunctions ? false : !isCustomerBanned,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsToBookingCreated(tx) || txIsPaymentPending(tx) || txIsDepositPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx) || txIsDepositExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsAccepted(tx) || txIsAcceptedDeposit(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsCancelled(tx) || txIsCancelledBooked(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsBooked(tx)) {
        const hasAdditionlPendingPayments = txHasAdditionalPaymentInitiated(tx);
        if (hasAdditionlPendingPayments) {
          return {
            headingState: HEADING_PAYMENT_PENDING,
            showDetailCardHeadings: isCustomer,
          };
        }
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };

    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price, true)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onDeclineEnquiry={type => onDeclineEnquiry(currentTransaction, type)}
        isProvider={isProvider}
        worktripp={currentTransaction.attributes.protectedData.worktripp}
        setIsProposedPriceModalOpen={this.setIsProposedPriceModalOpen}
        paymentInProgress={paymentInProgress}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    const AcceptAndPayBtnMaybe = props => {
      const { createdAt, bookingStart } = metadata.activeProposal || {};
      const isAdditionalPaymentDue = hasAdditonalPaymentPendingStatus(
        currentTransaction.attributes.metadata?.additionalTransactions
      );

      const isInTransitionOfPayment = isPayingTransition(
        currentTransaction.attributes.lastTransition,
        isAdditionalPaymentDue
      );
      const neededTxId = currentTransaction.id.uuid;

      const expiryDate = new Date(new Date(createdAt).setDate(new Date(createdAt).getDate() + 6));
      const isExpired = new Date() - expiryDate > 0 || new Date() >= new Date(bookingStart);

      let { btnText, disabled } = getPayingButtonText(currentTransaction);

      const onClick = () => {
        if (txIsToQuoteSent(currentTransaction) || txIsToBookingCreated(currentTransaction)) {
          this.setState({ customLoading: true });
          onAcceptSaleCustomer(currentTransaction).catch(e => {
            this.setState({ customLoading: false });
          });
          return;
        }
        if (txHasAdditionalPaymentInitiated(currentTransaction)) {
          this.setState({ customLoading: true });
          onAcceptSaleCustomerAdditionalTx(currentTransaction).catch(e => {
            this.setState({ customLoading: false });
          });
          return;
        }

        window.location.assign(`/order/${neededTxId}/details/redirect`);
      };
      const showAcceptAndPayButton =
        isCustomer &&
        createdAt &&
        bookingStart &&
        isInTransitionOfPayment &&
        btnText !== null &&
        !isExpired &&
        !blockAllTxFunctions;

      if (!showAcceptAndPayButton) {
        return <div />;
      }

      return (
        <Button
          style={{ marginTop: props.withMargin ? '40px' : '0' }}
          onClick={onClick}
          disabled={disabled}
        >
          {btnText}
        </Button>
      );
    };

    const WtDetailsView = () => {
      const { attributes, booking, listing } = currentTransaction;
      const { protectedData = {}, metadata, lastTransition } = attributes;
      const { name, noOfParticipants, minBudget, goals, description } =
        protectedData.worktripp || {};
      const { publicData, title, price } = listing.attributes;
      const { bookingStart, bookingEnd, infoToShare = [] } = metadata.bookingDetails || {};

      const goalsFilter = findOptionsForSelectFilter('goals', config.custom.filters);
      function getLabelForKeyFilter(key) {
        return goalsFilter.find(g => g.key == key).label;
      }

      return (
        <div className={css.rightpanel}>
          <h2 className={publicData.serviceType == 'CFS' ? css.serviceTypeCFS : css.serviceTypeVE}>
            {unparseListingType(publicData.listingType)}
          </h2>
          <div className={css.logocardWrapper}>
            <img src={CardLogo} />
            <h4 className={css.summary}>WorkTripp Summary</h4>
          </div>
          <h2 className={css.worktrippName}>{name}</h2>
          {isCustomer || (isProvider && infoToShare.includes('companyName')) ? (
            <div className={css.organisationName}>
              Created by {currentCustomer?.attributes?.profile?.publicData?.organisationName}
            </div>
          ) : null}

          {isCustomer || (isProvider && infoToShare.includes('workTrippDescription')) ? (
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
          ) : null}

          {goals?.map(g => {
            return (
              <div key={`goal-${g}`}>
                <img src={GoalLogo} className={css.goalImage} />
                {getLabelForKeyFilter(g)}
              </div>
            );
          })}
          <div>
            <hr></hr>
            {isCustomer || (isProvider && infoToShare.includes('totalBudget')) ? (
              <div className={css.totalBudget}>Budget per person: £{minBudget}</div>
            ) : null}
            {bookingStart ? (
              <div className={css.dateWrapper}>
                <img src={CalendarLogo} />
                {new Date(bookingStart).toDateString()} - {new Date(bookingEnd).toDateString()}
              </div>
            ) : (
              <div>
                {booking?.attributes?.start?.toDateString()} -{' '}
                {booking?.attributes?.end?.toDateString()}
              </div>
            )}
          </div>

          {isCustomer || (isProvider && infoToShare.includes('noOfParticipants')) ? (
            <div className={css.peopleWrapper}>
              <img src={GroupLogo} />
              For {noOfParticipants} people
            </div>
          ) : null}

          <div className={css.acceptNpay}>
            <AcceptAndPayBtnMaybe />
          </div>

          {lastTransition == TRANSITION_COMPLETE ||
          lastTransition == TRANSITION_COMPLETE_BY_OPERATOR ||
          (lastTransition === transitions.REVIEW_1_BY_CUSTOMER && isProvider) ||
          (lastTransition === transitions.REVIEW_1_BY_PROVIDER && isCustomer) ? (
            <PrimaryButton onClick={this.onOpenReviewModal} style={{ marginTop: '40px' }}>
              Leave a review
            </PrimaryButton>
          ) : null}
        </div>
      );
    };

    getProposalInitialValues(currentTransaction?.attributes.metadata);
    return (
      <div className={classes}>
        {blockAllTxFunctions ? <OldTxWarning isProvider={isProvider} /> : null}
        <div className={css.container}>
          {this.state.customLoading ? <CustomSpinner /> : null}

          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
              listingType={publicData.listingType}
            />

            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />

              <WtDetailsView />
              {!blockAllTxFunctions &&
              (lineItems || (savedLineItems && savedLineItems.length > 0)) ? (
                <BreakdownMaybe
                  transaction={currentTransaction}
                  transactionRole={transactionRole}
                />
              ) : null}
            </div>

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
              isCustomer={isCustomer}
            />
            {showSendMessageForm ? (
              metadata.proposalId && isCustomer && !this.state.showChat ? (
                <div className={css.chatHolder}>
                  <AcceptAndPayBtnMaybe />

                  <PrimaryButton
                    onClick={() => {
                      this.setState({ showChat: true });
                    }}
                  >
                    Continue to Chat
                  </PrimaryButton>
                </div>
              ) : (
                <>
                  <SendMessageForm
                    formId={this.sendMessageFormName}
                    rootClassName={css.sendMessageForm}
                    messagePlaceholder={sendMessagePlaceholder}
                    inProgress={sendMessageInProgress}
                    sendMessageError={sendMessageError}
                    onFocus={this.onSendMessageFormFocus}
                    onBlur={this.onSendMessageFormBlur}
                    onSubmit={this.onMessageSubmit}
                    transactionRole={transactionRole}
                  />

                  <AcceptAndPayBtnMaybe withMargin={true} />
                </>
              )
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}

            {paymentExpired && (
              <div style={{ color: 'var(--attentionColor)' }}>
                <FormattedMessage id="ActivityFeed.transitionExpirePayment" />
              </div>
            )}

            {lastTransition == TRANSITION_COMPLETE ||
            lastTransition == TRANSITION_COMPLETE_BY_OPERATOR ||
            (lastTransition == transitions.REVIEW_1_BY_CUSTOMER && isProvider) ||
            (lastTransition == transitions.REVIEW_1_BY_PROVIDER && isCustomer) ? (
              <PrimaryButton onClick={this.onOpenReviewModal} style={{ marginTop: '40px' }}>
                Leave a review
              </PrimaryButton>
            ) : null}

            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}

            {blockAllTxFunctions ? null : (
              <ChatActionsSection
                transactionId={currentTransaction.id.uuid}
                currentUserId={currentUser.id.uuid}
                isCustomer={isCustomer}
                providerPhoneNumber={currentProvider?.attributes?.profile?.publicData?.phoneNumber}
              />
            )}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
              />

              <DetailCardHeadingsMaybe
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                listingTitle={listingTitle}
                // subTitle={bookingSubTitle}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
                isCustomer={isCustomer}
                currentProvider={currentProvider}
              />

              {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                />
              ) : null}

              <div className={css.desktopActionButtons}>
                <WtDetailsView />
              </div>

              {!blockAllTxFunctions &&
              (lineItems || (savedLineItems && savedLineItems.length > 0)) ? (
                <BreakdownMaybe
                  className={css.breakdownContainer}
                  transaction={currentTransaction}
                  transactionRole={transactionRole}
                />
              ) : null}

              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />

        <ProposePriceModal
          isOpen={this.state.isProposedPriceModalOpen}
          onClose={() => {
            this.setIsProposedPriceModalOpen(false);
          }}
          onManageDisableScrolling={onManageDisableScrolling}
          closeButtonMessage={closeButtonMessage}
          customerDisplayName={customerDisplayName}
          initialValues={getProposalInitialValues(currentTransaction?.attributes.metadata)}
          onSubmit={values => {
            onSendQuote(currentTransaction, values);
            this.setIsProposedPriceModalOpen(false);
          }}
        />

        {isProvider && (
          <PhoneNumberModal
            isOpen={this.state.isPhoneNumberModalOpen}
            onClose={() => {
              this.setState({
                isPhoneNumberModalOpen: false,
              });
            }}
            onManageDisableScrolling={onManageDisableScrolling}
            closeButtonMessage={closeButtonMessage}
          />
        )}
        <TutorialsChatPage
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
        />
      </div>
    );
  }
}

const getProposalInitialValues = metadata => {
  let noOfParticipants = metadata.bookingDetails?.infoToShare?.includes('noOfParticipants')
    ? metadata.bookingDetails?.noOfParticipants
    : undefined;
  return {
    bookingDates: {
      startDate: new Date(metadata.bookingDetails?.bookingStart),
      endDate: new Date(metadata.bookingDetails?.bookingEnd),
    },
    noOfParticipants: noOfParticipants,
    workScope: metadata.activeProposal?.workScope,
    vatPercentage: metadata.activeProposal?.quote?.vatPercentage,
  };
};

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onSendQuote: func.isRequired,
  onAcceptSaleCustomer: func.isRequired,
  onDeclineEnquiry: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
