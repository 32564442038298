import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import {
  attachAuthorAndImagesToListings,
  getSelectedWorktripp,
  unparseListingType,
  wtListingLimitReached,
} from '../../util/misc';
import { parse, stringify } from '../../util/urlHelpers';
import {
  searchListings,
  getWorktrippsList,
  editWorktripp,
  selectWorktripp,
} from '../../containers/WorktrippPage/Worktripp.duck';
import config from '../../config';
import { ModalInMobile, Button, IconSpinner } from '../../components';
import { BookingDatesForm } from '../../forms';
import css from './BookingPanel.module.css';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price, true);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    authorDisplayName,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    worktrippView = false,
    currentUser,
    onToggleEnquiryModal,
    setSelectedWorktripp,
    selectedWorktripp,
  } = props;

  const dispatch = useDispatch();
  const { currentListings, worktrippslist: worktripps, refreshWorktripps } = useSelector(
    state => state.Worktripp
  );
  const { updateInProgress } = useSelector(state => state.ProfileSettingsPage);
  const [allListings, setAllListings] = useState([]);

  const price = listing.attributes.price;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = hasListingState && !isClosed && !worktrippView;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;
  const [worktrippErrorMessage, setWorktrippErrorMessage] = useState(null);

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  useEffect(() => {
    if (worktripps.length > 0) {
      setSelectedWorktripp(getSelectedWorktripp(worktripps));
    }
  }, [worktripps]);

  let buttonMessage = <IconSpinner />;
  let color = null;
  let enquiryAvailable = false;
  let goToTxPage = false;

  const checkIfListingHasBeenContacted = () => {
    const listingsInSelectedWorktripp = selectedWorktripp?.listings;
    const listingInSelectedWorktripp = listingsInSelectedWorktripp?.find(
      ls => ls.listing_id == listing.id.uuid
    );
    if (listingInSelectedWorktripp) return listingInSelectedWorktripp;
    return false;
  };

  const updateActionButton = () => {
    const listingInSelectedWorktripp = checkIfListingHasBeenContacted();
    if (listingInSelectedWorktripp) {
      const listingHasBeenContacted = listingInSelectedWorktripp.tx_id != 'NOT CONTACTED';
      buttonMessage = listingHasBeenContacted ? (
        <FormattedMessage id="ListingPage.worktrippEnquired" />
      ) : (
        <FormattedMessage id="ListingPage.enquireWorktripp" />
      );
      goToTxPage = listingHasBeenContacted ? listingInSelectedWorktripp.tx_id : false;
      color = 'green';
      enquiryAvailable = !listingHasBeenContacted;
      return false;
    }
    if (isOwnListing) {
      buttonMessage = <FormattedMessage id="ListingPage.ownListingButton" />;
      return true;
    }
    if (wtListingLimitReached(allListings, listing)) {
      color = 'red';
      buttonMessage = <FormattedMessage id="ListingPage.max5Listings" />;
      setWorktrippErrorMessage(
        'You can add up to 5 listings in your shortlist. Remove some from Your WorkTripp before adding more.'
      );
      return true;
    }
    buttonMessage = <FormattedMessage id="ListingPage.addToYourWorktripp" />;
    return false;
  };

  const onSelectWorktripp = selectedId => {
    const allIds = worktripps.map(w => w._id).filter(id => id !== selectedId);
    const selectedWorktripp = worktripps.find(id => id == selectedId);
    setSelectedWorktripp(selectedWorktripp);
    dispatch(selectWorktripp({ selectedId, allIds }));
  };

  const onAddToWorktripp = () => {
    const selectedWt = selectedWorktripp?._id;
    if (selectedWt) {
      dispatch(
        editWorktripp({
          _id: selectedWt,
          listings: [
            ...selectedWorktripp.listings,
            {
              listing_id: listing.id.uuid,
              tx_id: 'NOT CONTACTED',
            },
          ],
        })
      );
    } else {
      window.location.assign(`/editWorktripp/new?listingId=${listing.id.uuid}`);
    }
  };

  useEffect(() => {
    if (selectedWorktripp && selectedWorktripp.listings && selectedWorktripp.listings.length > 0) {
      dispatch(searchListings({ ids: selectedWorktripp.listings.map(l => l.listing_id) }));
    } else {
      setAllListings([]);
    }
  }, [selectedWorktripp]);

  useEffect(() => {
    if (currentListings?.data?.length) {
      const data = attachAuthorAndImagesToListings(currentListings);
      setAllListings(data);
    }
  }, [currentListings]);

  useEffect(() => {
    if (currentUser) {
      dispatch(getWorktrippsList({ user_id: currentUser.id.uuid }));
    }
  }, [currentUser, refreshWorktripps]);

  const WtPanel = () => {
    return (
      <>
        {worktrippView && (
          <div className={css.bookingHeading}>
            <h2 className={titleClasses}>{title}</h2>

            {updateInProgress ? (
              <div>Loading...</div>
            ) : (
              <>
                {worktripps?.length > 0 && (
                  <>
                    <label htmlFor="selectedWorktripp">Select a worktripp:</label>
                    <select
                      onChange={e => {
                        onSelectWorktripp(e.target.value);
                      }}
                      value={selectedWorktripp?._id}
                      id="selectedWorktripp"
                    >
                      <option value="">Please select</option>
                      {worktripps.map(w => (
                        <option key={w._id} value={w._id}>
                          {w.name}
                        </option>
                      ))}
                    </select>
                  </>
                )}
                <br />
                <Button
                  rootClassName={css.bookButton}
                  disabled={updateActionButton()}
                  style={color ? { backgroundColor: color } : {}}
                  onClick={() => {
                    if (enquiryAvailable) {
                      return onToggleEnquiryModal();
                    } else if (goToTxPage) {
                      return window.location.assign(`/order/${goToTxPage}/details`);
                    } else {
                      return onAddToWorktripp();
                    }
                  }}
                >
                  {buttonMessage}
                </Button>
                {worktrippErrorMessage && (
                  <p className={css.maxListings}>{worktrippErrorMessage}</p>
                )}
                {selectedWorktripp && (
                  <div className={css.selectedWtContainer}>
                    <div>
                      <Button
                        className={css.actionPanel}
                        disabled={!selectedWorktripp}
                        onClick={() =>
                          window.location.assign(`/viewWorktripp/${selectedWorktripp?._id}`)
                        }
                      >
                        <FormattedMessage id="ListingPage.manageWorktripp" />
                      </Button>
                      <Button
                        className={css.actionPanel}
                        onClick={() => window.location.assign(`/s`)}
                      >
                        <FormattedMessage id="ListingPage.browseMore" />
                      </Button>
                    </div>
                    <br></br>

                    <div>Selected WorkTripp: {selectedWorktripp?.name}</div>
                    {/* <div className={css.liveListings}>Shortlist</div>
            <div>
              {allListings?.map(l => {
                const { serviceType, listingType } = l.attributes.publicData;
                return <div key={l.id.uuid} onClick={() => { window.location.assign(`/l/${l.id.uuid}`) }} className={css.wtListingCard}>
                  <div>
                    <div>{l.attributes.title}</div>
                    <div style={{ marginTop: '-8px' }}>{priceData(l.attributes.price, intl).formattedPrice}</div>
                  </div>
                  <div className={serviceType == 'CFS' ? css.serviceTypeCFS : css.serviceTypeVE}>
                    {unparseListingType(listingType)}
                  </div>
                </div>
              })}
            </div> */}

                    {!allListings.find(l => l.attributes.publicData.listingType == 'venue') && (
                      <div className={css.noVenueInWorktripp}>
                        <FormattedMessage id="ListingPage.noVenueInWorktripp" />
                        <a href={`/s/venue/?pub_listingType=venue`} className={css.addOneNow}>
                          Add one now?
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <WtPanel />

        {showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
          />
        ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>

        {showBookingDatesForm || worktrippView ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
