import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { TRANSITIONS } from '../../util/transaction';
import { parse } from '../../util/urlHelpers';
import config from '../../config';
import axios from 'axios';

const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';
export const LOAD_TRANSATIONS_SUCCESS = 'app/SearchPage/LOAD_TRANSATIONS_SUCCESS';
export const LOAD_WORKTRIPPS_SUCCESS = 'app/WorktrippPage/LOAD_WORKTRIPPS_SUCCESS';
export const WORKTRIPP_DELETED = 'app/WorktrippPage/WORKTRIPP_DELETED';
export const LOAD_WORKTRIPP_SUCCESS = 'app/WorktrippPage/LOAD_WORKTRIPP_SUCCESS';
export const LOAD_PARTICIPANTS_SUCCESS = 'app/WorktrippPage/LOAD_PARTICIPANTS_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentListings: [],
  transactionsStatus: [],
  worktrippslist: [],
  worktripp: {},
  refreshWorktripps: 0,
  participants: [],
};

const WorktrippReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentListings: payload.data,
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };
    case LOAD_TRANSATIONS_SUCCESS:
      return { ...state, transactionsStatus: payload.data };
    case LOAD_WORKTRIPPS_SUCCESS:
      return { ...state, worktrippslist: payload };
    case WORKTRIPP_DELETED:
      return { ...state, refreshWorktripps: (state.refreshWorktripps += 1) };
    case LOAD_WORKTRIPP_SUCCESS:
      return { ...state, refreshWorktripps: (state.refreshWorktripps += 1), worktripp: payload };
    case LOAD_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: payload,
      };
    default:
      return state;
  }
};

export default WorktrippReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const loadTransactionsSuccess = response => ({
  type: LOAD_TRANSATIONS_SUCCESS,
  payload: { data: response },
});

export const loadWorktrippsSuccess = response => ({
  type: LOAD_WORKTRIPPS_SUCCESS,
  payload: response,
});

export const worktrippDeleted = () => ({
  type: WORKTRIPP_DELETED,
  payload: null,
});

export const loadWorktrippSuccess = response => ({
  type: LOAD_WORKTRIPP_SUCCESS,
  payload: response,
});

export const loadParticipantsSuccess = response => ({
  type: LOAD_PARTICIPANTS_SUCCESS,
  payload: response,
});

// FUNCTIONS

export const getWorktrippsList = ({
  user_id,
  subUserId,
  permission,
  workTripListOpened,
  ignoreUpdateProfile,
}) => (dispatch, getState, sdk) => {
  const params =
    permission == 'User'
      ? {
          user_id,
          subUserId,
        }
      : {
          user_id,
        };

  axios
    .post(`/api/worktripp/list`, params, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    })
    .then(async result => {
      dispatch(loadWorktrippsSuccess(result.data));
      if (!ignoreUpdateProfile) {
        let publicData = {
          worktrippCount: result?.data ? result.data.length : 0,
        };
        if (workTripListOpened) {
          publicData = {
            ...publicData,
            workTripListOpened,
          };
        }
        sdk.currentUser.updateProfile({
          publicData,
        });
      }

      return result.data;
    })
    .catch(err => console.log(err));
};

export const deleteWorktripp = _id => dispatch => {
  axios
    .post(
      `/api/worktripp/delete`,
      {
        _id,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(result => {
      dispatch(worktrippDeleted());
      return result.data;
    })
    .catch(err => console.log(err));
};

export const getWorktripp = _id => dispatch => {
  axios
    .post(
      `/api/worktripp/get`,
      {
        _id,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(result => {
      dispatch(loadWorktrippSuccess(result.data));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const editWorktripp = params => dispatch => {
  axios
    .post(`/api/worktripp/edit`, params, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    })
    .then(result => {
      dispatch(loadWorktrippSuccess(result.data));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const selectWorktripp = params => dispatch => {
  axios
    .post(`/api/worktripp/select`, params, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    })
    .then(result => {
      dispatch(loadWorktrippsSuccess(result.data));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const createWorktripp = params => dispatch => {
  axios
    .post(`/api/worktripp/create`, params, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    })
    .then(result => {
      dispatch(loadWorktrippSuccess(result.data));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;

  const params = {
    ...rest,
    per_page: perPage,
    include: ['author', 'images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      dispatch(loadTransactions());
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const loadTransactions = () => (dispatch, getState, sdk) => {
  // this is used to load all the transactions attached to the worktripp listings
  const page = 1;

  const apiQueryParams = {
    only: 'order',
    lastTransitions: TRANSITIONS,
    page,
    per_page: 100,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactionsStatus = response?.data.data.map(tx => {
        return {
          id: tx.id.uuid,
          status: tx.attributes.lastTransition,
          metadata: tx.attributes.metadata,
          txVersion: tx.attributes.processVersion,
        };
      });
      dispatch(loadTransactionsSuccess(transactionsStatus));
      return transactionsStatus;
    })
    .catch(e => {
      throw e;
    });
};

export const getParticipants = worktripp_id => (dispatch, getState, sdk) => {
  axios
    .post(
      `/api/worktripp/participants/get`,
      {
        worktripp_id,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(result => {
      dispatch(loadParticipantsSuccess(result.data));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const addParticipant = params => (dispatch, getState, sdk) => {
  axios
    .post(`/api/worktripp/participants/add`, params, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    })
    .then(result => {
      dispatch(loadParticipantsSuccess(result.data));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const deleteParticipant = params => (dispatch, getState, sdk) => {
  axios
    .post(`/api/worktripp/participants/delete`, params, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    })
    .then(result => {
      dispatch(loadParticipantsSuccess(result.data));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const changeParticipant = params => (dispatch, getState, sdk) => {
  axios
    .post(`/api/worktripp/participants/change`, params, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    })
    .then(result => {
      dispatch(loadParticipantsSuccess(result.data));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
  });
};
